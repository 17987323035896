import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { RadioGroup } from "@headlessui/react";

import LayoutWidget from "../../../components/widgets/layout";
import Seo from "../../../components/seo";
import useTranslation from "../../../hooks/useTranslation";

const voucherAmounts = [50, 100, 200];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const GiftCartWidgetDemo = () => {
	const { language } = useTranslation();
	const [selectedVoucherAmount, setSelectedVoucherAmount] = useState(
		voucherAmounts[1]
	);
	console.debug(
		"🦄 ~ GiftCartWidgetDemo ~ selectedVoucherAmount:",
		selectedVoucherAmount
	);

	return (
		<LayoutWidget>
			<Seo
				seo={{
					metaTitle: "Gift card Widget",
					metaDescription:
						"The new rentware gift card widget enables you to sell gift cards directly on your website",
				}}
			/>

			<div className="hidden text-center lg:block">
				<StaticImage
					src="../../../images/tesla.jpg"
					alt="Tesla red"
					layout="constrained"
					width={1440}
					quality="80"
				/>
			</div>

			<div className="mx-auto my-8 grid max-w-5xl items-end gap-6 px-4 md:grid-cols-2 md:gap-16 md:px-6 md:pb-20">
				<div className="grid h-full max-w-md gap-4 md:max-w-none">
					<RadioGroup
						value={selectedVoucherAmount}
						onChange={setSelectedVoucherAmount}
						className="mt-4 md:hidden"
					>
						<RadioGroup.Label className="sr-only">
							Choose a voucher value
						</RadioGroup.Label>
						<div className="grid grid-flow-col gap-4">
							{voucherAmounts.map((amount) => (
								<RadioGroup.Option
									key={amount}
									value={amount}
									checked={amount === selectedVoucherAmount}
									className={({ active }) =>
										classNames(
											active ? "ring-2 ring-black" : "",
											"group relative flex cursor-pointer items-center justify-center rounded-md border bg-white py-3 px-3 text-sm font-medium uppercase text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none sm:flex-1"
										)
									}
								>
									{({ active, checked }) => (
										<>
											<RadioGroup.Label as="p">€{amount}</RadioGroup.Label>
											<div
												className={classNames(
													active ? "border" : "border-2",
													checked ? "border-black" : "border-transparent",
													"pointer-events-none absolute -inset-px rounded-md"
												)}
												aria-hidden="true"
											/>
										</>
									)}
								</RadioGroup.Option>
							))}
						</div>
					</RadioGroup>

					<div className="grid min-h-[265px] place-items-center">
						{voucherAmounts.map((amount) => (
							<div
								className="voucher-container col-start-1 row-start-1"
								data-show={amount === selectedVoucherAmount}
							>
								<rtr-giftcard fixed-amount={amount}></rtr-giftcard>
							</div>
						))}
					</div>
				</div>
				<div className="prose">
					<h1>
						{language === "de"
							? "Erlebnisse verschenken"
							: "Experiences as a gift"}
					</h1>
					<p>
						{language === "de"
							? "Verschenke eine Autofahrt! Deinen Freunden, Verwandten und Bekannten werden sich freuen über ein unvergessliches Erlebnis hinter dem Steuer unserer Fahrzeuge."
							: "Give a car trip as a gift! Your friends, relatives and acquaintances will be delighted with an unforgettable experience behind the wheel of our vehicles."}
					</p>

					<RadioGroup
						value={selectedVoucherAmount}
						onChange={setSelectedVoucherAmount}
						className="mt-4 hidden md:block"
					>
						<RadioGroup.Label className="sr-only">
							Choose a voucher value
						</RadioGroup.Label>
						<div className="grid grid-flow-col gap-4">
							{voucherAmounts.map((amount) => (
								<RadioGroup.Option
									key={amount}
									value={amount}
									checked={amount === selectedVoucherAmount}
									className={({ active }) =>
										classNames(
											active ? "ring-2 ring-black" : "",
											"group relative flex cursor-pointer items-center justify-center rounded-md border bg-white py-3 px-3 text-sm font-medium uppercase text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none sm:flex-1"
										)
									}
								>
									{({ active, checked }) => (
										<>
											<RadioGroup.Label as="p">€{amount}</RadioGroup.Label>
											<div
												className={classNames(
													active ? "border" : "border-2",
													checked ? "border-black" : "border-transparent",
													"pointer-events-none absolute -inset-px rounded-md"
												)}
												aria-hidden="true"
											/>
										</>
									)}
								</RadioGroup.Option>
							))}
						</div>
					</RadioGroup>
				</div>
			</div>
		</LayoutWidget>
	);
};

export default GiftCartWidgetDemo;
